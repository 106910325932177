@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  .hide-scroll-bar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .hide-scroll-bar::-webkit-scrollbar {
    display: none;
  }

  [data-theme="default"] {
    --color-brand-50: 244 242 241;
    --color-brand-100: 235 229 224;
    --color-brand-200: 213 189 170;
    --color-brand-300: 207 162 125;
    --color-brand-400: 212 147 94;
    --color-brand-500: 221 132 60;
    --color-brand-600: 209 105 21;
    --color-brand-700: 152 83 27;
    --color-brand-800: 101 60 27;
    --color-brand-900: 56 36 21;
    --color-brand-950: 34 25 17;
    --color-cta-light: 221 132 60;
    --color-cta: 209 105 21;
    --color-cta-dark: 152 83 27;
  }

  [data-theme="cbb"] {
    --color-brand-50: 241 248 250;
    --color-brand-100: 220 237 241;
    --color-brand-200: 188 219 229;
    --color-brand-300: 142 192 210;
    --color-brand-400: 89 158 183;
    --color-brand-500: 61 129 157;
    --color-brand-600: 52 103 129;
    --color-brand-700: 49 87 109;
    --color-brand-800: 47 75 91;
    --color-brand-900: 43 64 78;
    --color-brand-950: 24 40 52;
    --color-cta-light: 61 129 157;
    --color-cta: 61 129 157;
    --color-cta-dark: 49 87 109;
  }

  [data-theme="sg"] {
    --color-brand-50: 229 242 255;
    --color-brand-100: 184 208 230;
    --color-brand-200: 138 173 207;
    --color-brand-300: 92 138 184;
    --color-brand-400: 46 103 161;
    --color-brand-500: 0 68 138;
    --color-brand-600: 0 57 114;
    --color-brand-700: 0 46 92;
    --color-brand-800: 0 35 70;
    --color-brand-900: 0 24 48;
    --color-brand-950: 0 13 26;
    --color-cta-light: 229 63 76;
    --color-cta: 206 20 49;
    --color-cta-dark: 152 27 46;
  }

  [data-theme="natone"] {
    --color-brand-50: 233 240 252;
    --color-brand-100: 195 213 248;
    --color-brand-200: 155 187 242;
    --color-brand-300: 115 161 236;
    --color-brand-400: 35 135 230;
    --color-brand-500: 35 109 224;
    --color-brand-600: 27 91 182;
    --color-brand-700: 21 71 142;
    --color-brand-800: 15 51 102;
    --color-brand-900: 9 31 62;
    --color-brand-950: 3 11 22;
    --color-cta-light: 119 165 60;
    --color-cta: 98 159 20;
    --color-cta-dark: 77 106 40;
  }

  [data-theme="catupiry"] {
    --color-brand-50: 253 246 186;
    --color-brand-100: 229 217 186;
    --color-brand-200: 206 187 142;
    --color-brand-300: 183 157 98;
    --color-brand-400: 160 127 54;
    --color-brand-500: 137 97 10;
    --color-brand-600: 116 81 10;
    --color-brand-700: 93 65 8;
    --color-brand-800: 70 49 6;
    --color-brand-900: 47 33 4;
    --color-brand-950: 24 17 2;
    --color-cta-light: 229 63 76;
    --color-cta: 211 46 41;
    --color-cta-dark: 145 23 19;
  }

  [data-theme="rioquality"] {
    --color-brand-50: 230 255 241;
    --color-brand-100: 213 237 223;
    --color-brand-200: 139 194 162;
    --color-brand-300: 93 164 123;
    --color-brand-400: 47 134 84;
    --color-brand-500: 1 104 45;
    --color-brand-600: 0 89 39;
    --color-brand-700: 0 73 32;
    --color-brand-800: 0 57 25;
    --color-brand-900: 0 41 18;
    --color-brand-950: 0 25 11;
    --color-cta-light: 1 104 45;
    --color-cta: 0 89 39;
    --color-cta-dark: 0 73 32;
  }

  [data-theme="prime"] {
    --color-brand-50: 247 243 238;
    --color-brand-100: 227 216 203;
    --color-brand-200: 206 189 169;
    --color-brand-300: 185 162 135;
    --color-brand-400: 164 135 101;
    --color-brand-500: 143 108 67;
    --color-brand-600: 117 89 56;
    --color-brand-700: 92 70 44;
    --color-brand-800: 67 51 32;
    --color-brand-900: 42 32 20;
    --color-brand-950: 17 13 8;
    --color-cta-light: 140 110 87;
    --color-cta: 116 92 70;
    --color-cta-dark: 91 72 55;
  }
}
